import React, { useRef, useEffect, useState } from "react";
import { Form, Button, Container, Spinner, Row, Image } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { account } from "../appwrite/appwriteConfig";
import Axios from "axios";
import Logo from "../img/integrativeLogo.svg";

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const [confirmPassword, setConfirmPassword] = useState("");
    const [formValid, setFormValid] = useState(false);

    const userRef = useRef();
    const errRef = useRef();

    const createAccount = async (userInfo) => {
        if (userInfo === false) {
            setUser({
                password: "",
            });
            setErrMsg("Usuário não registrado!");
            return;
        }
        try {
            const response = await account.create(
                "unique()",
                userInfo.email,
                user.password,
                `${userInfo.first_name} ${userInfo.last_name}`
            );
            console.log(response);
            if (response) {
                setUser({
                    email: "",
                    password: "",
                });
                navigate("/");
            } else {
                setErrMsg("Alguma coisa deu errado. Tente novamente.");
            }
        } catch (error) {
            setErrMsg(error.message);
            console.log(error);
        }
    };

    const registerUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            url: `https://app.editoraintegrative.com/api/check_to_register.php?secretKey=${process.env.REACT_APP_PHP_SECRET_KEY}`,
            data: JSON.stringify({ email: user.email }),
        };

        Axios(options)
            .then((response) => {
                createAccount(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setErrMsg(error);
                console.log("error", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailToRegister = searchParams.get("emailToRegister");
        if (!emailToRegister) {
            navigate("/");
        }
        setUser({
            ...user,
            email: emailToRegister,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    const validateForm = () => {
        if (user.password !== confirmPassword) {
            setFormValid(false);
            return;
        }
        setFormValid(true);
    };

    useEffect(() => {
        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, confirmPassword]);

    return (
        <>
            <Container className="my-2">
                <Row className="align-items-center justify-content-center">
                    <Image
                        className="loginLogo"
                        alt="Logo Editora Integrative"
                        src={Logo}
                        fluid
                    />
                </Row>
                <Row className="align-items-center justify-content-center">
                    <h2>Editora Integrative</h2>
                </Row>
            </Container>
            <Container className="container d-flex justify-content-center align-content-center m-auto">
                <Form style={{ width: "30em" }} className="text-left">
                    <Form.Group className="mb-3" controlId="passwordLogin">
                        <Form.Label>Registrar Password</Form.Label>
                        <Form.Control
                            ref={userRef}
                            type="password"
                            placeholder="Password"
                            required
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    password: e.target.value,
                                });
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Password Invalido.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Label>Confirme Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            isInvalid={confirmPassword !== user.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            Passwords diferentes.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Text
                        className={
                            errMsg ? "text-danger text-center mb-2" : "d-none"
                        }
                        ref={errRef}
                        aria-live="assertive"
                    >
                        {errMsg}
                    </Form.Text>
                    {isLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    ) : (
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={registerUser}
                            disabled={!formValid}
                        >
                            Submit
                        </Button>
                    )}
                </Form>
            </Container>
        </>
    );
};

export default Register;
