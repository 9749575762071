import React from "react";
import { Container, Image, Row, Button, Card } from "react-bootstrap";
import { account } from "../appwrite/appwriteConfig";
import { useNavigate } from "react-router-dom";
import Logo from "../img/integrativeLogo.svg";
import useAuth from "../hooks/useAuth";

const Header = () => {
    const { auth } = useAuth();
    const navigate = useNavigate();

    const LogoutUser = async (e) => {
        e.preventDefault();
        try {
            if (auth.sessionId) {
                await account.deleteSession(auth.sessionId);
                navigate("/login");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <header>
            <Container className="mt-0 px-0">
                <Card.Header className="text-right d-flex justify-content-between mb-4">
                    <Card.Title className="name-title my-auto">
                        Ola, {auth ? auth.email : "Loading..."}
                    </Card.Title>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={LogoutUser}
                    >
                        Logout
                    </Button>
                </Card.Header>
                <Row className="align-items-center justify-content-center mx-3">
                    <Image src={Logo} fluid />
                </Row>
                <Row className="align-items-center justify-content-center mx-3">
                    <h2>Editora Integrative</h2>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
