import { Client, Account, Databases, Storage } from "appwrite";

const client = new Client();

client
    .setEndpoint(process.env.REACT_APP_APP_WRITE_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

export const account = new Account(client);

// Database
export const databases = new Databases(client, process.env.REACT_APP_DB_ID);

// Storage
export const storage = new Storage(client);
