import axios from "axios";

const getBook = async () => {
    let headersList = {
        Accept: "*/*",
        "X-Custom-Auth-Key": process.env.REACT_APP_X_CUSTOM_AUTH_KEY,
    };

    return axios
        .get(process.env.REACT_APP_BOOK_URL, {
            headers: headersList,
            responseType: "blob",
        })
        .then(async (response) => {
            const data = await response.data;
            const blob = new Blob([data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            return url;
        })
        .catch((error) => {
            throw new Error(`HTTP error! status: ${error}`);
        });
};

export default getBook;
