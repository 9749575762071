import React from "react";
import { Card, Container } from "react-bootstrap";

import Header from "./Header";
import Menu from "./Menu";

const Home = () => {
    return (
        <Container className="px-0">
            <Header />
            <Card.Body className="text-center">
                <h3 className="my-2">
                    Seja Bem vindo ao aplicativo da Editora Integrative!
                </h3>
                <div className="text-center my-4">
                    <h5>
                        Aqui você tera acesso aos livros da Editora Integrative
                        e parceiros.
                    </h5>
                    <h5>Clique no botão library para acessar seus livros.</h5>
                </div>
            </Card.Body>
            <Menu />
        </Container>
    );
};

export default Home;
