import React, { useEffect } from "react";
import { Container, Card, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import bookImg from "../img/livro_single.png";
import getBook from "../hooks/getBook";

const DashBoardInfo = () => {
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchBook() {
            try {
                const url = await getBook();
                // Store the URL in local storage
                localStorage.setItem("bookUrl", url);
            } catch (error) {
                console.error(error);
            }
        }
        fetchBook();
    }, []);

    const handleImageClick = async () => {
        navigate("/bookview", { replace: true });
    };

    return (
        <Container>
            <Card.Body className="text-center">
                <>
                    <Card.Text>Click no livro abaixo para ler.</Card.Text>
                    <Link to="/bookview">
                        <Image
                            src={bookImg}
                            alt="Imagem Livro"
                            fluid
                            rounded
                            onClick={handleImageClick}
                            style={{
                                width: "150px",
                                height: "auto",
                                cursor: "pointer",
                            }}
                        ></Image>
                    </Link>
                </>
            </Card.Body>
        </Container>
    );
};

export default DashBoardInfo;
