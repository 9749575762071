import React from "react";
import {
    Container,
    ButtonGroup,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Menu = () => {
    const navigate = useNavigate();

    const goToLibrary = (e) => {
        e.preventDefault();
        navigate("/library");
    };

    const goToStore = (e) => {
        e.preventDefault();
        renderTooltip();
    };

    const goToHome = (e) => {
        e.preventDefault();
        navigate("/");
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Em Breve
        </Tooltip>
    );

    return (
        <>
            <Container className="px-0 fixed-bottom">
                <ButtonGroup size="md" className="w-100 text-center btn-equal">
                    <Button onClick={goToHome} variant="primary">
                        <p>Home</p> <i className="bi bi-house-fill"></i>
                    </Button>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button onClick={goToStore} variant="primary">
                            <p>Store</p> <i className="bi bi-shop"></i>
                        </Button>
                    </OverlayTrigger>
                    <Button onClick={goToLibrary} variant="primary">
                        <p>Library</p> <i className="bi bi-book"></i>
                    </Button>
                </ButtonGroup>
            </Container>
        </>
    );
};

export default Menu;
