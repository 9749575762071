import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Container, Spinner, Row, Image } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { account } from "../appwrite/appwriteConfig";
import Logo from "../img/integrativeLogo.svg";

const ForgotPassword = () => {
    const [email, setEmail] = useState();
    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const sendForgotPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await account.createRecovery(
                email,
                "https://app.editoraintegrative.com/resetPassord"
            );
            setIsLoading(false);
            navigate(from, { replace: true });
        } catch (error) {
            setIsLoading(false);
            if (error.message) setErrMsg(error.message);
            else setErrMsg("Erro ao enviar.");
        }
    };

    useEffect(() => {
        userRef.current.focus();
    }, []);

    return (
        <>
            <Container className="my-2">
                <Row className="align-items-center justify-content-center">
                    <Image
                        className="loginLogo"
                        alt="Logo Editora Integrative"
                        src={Logo}
                        fluid
                    />
                </Row>
                <Row className="align-items-center justify-content-center">
                    <h2>Editora Integrative</h2>
                </Row>
            </Container>
            <Container className="container d-flex justify-content-center align-content-center m-auto">
                <Form style={{ width: "30em" }} className="text-left">
                    <Form.Group className="mb-3" controlId="emailLogin">
                        <Form.Label>Email para redefinir senha.</Form.Label>
                        <Form.Control
                            ref={userRef}
                            type="email"
                            placeholder="Enter email"
                            required
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            value={email}
                        />
                        <Form.Text
                            className={
                                errMsg
                                    ? "text-danger text-center mb-2"
                                    : "d-none"
                            }
                            ref={errRef}
                            aria-live="assertive"
                        ></Form.Text>
                        {isLoading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        ) : (
                            <Button
                                className="mt-2"
                                variant="primary"
                                type="submit"
                                onClick={sendForgotPassword}
                            >
                                Enviar
                            </Button>
                        )}
                    </Form.Group>
                </Form>
            </Container>
        </>
    );
};

export default ForgotPassword;
