import React from "react";
import { Container } from "react-bootstrap";
import DashBoardInfo from "./DashBoardInfo";
import Menu from "./Menu";
import Header from "./Header";

const DashBoard = ({ user }) => {
    return (
        <Container className="px-0">
            <Header />
            <DashBoardInfo user={user} />
            <Menu />
        </Container>
    );
};

export default DashBoard;
