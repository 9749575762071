import React, { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Form, Button, Container, Spinner, Row, Image } from "react-bootstrap";
import { account } from "../appwrite/appwriteConfig";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../img/integrativeLogo.svg";

const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const loginUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await account.createEmailSession(
                user.email,
                user.password
            );
            setAuth({ userId: response.userId, email: response.providerUid });
            setUser({
                email: "",
                password: "",
            });
            setIsLoading(false);
            navigate(from, { replace: true });
        } catch (error) {
            setIsLoading(false);
            if (error.message) setErrMsg(error.message);
            else setErrMsg("Login Failed.");
        }
    };

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [user]);

    return (
        <>
            <Container className="my-2">
                <Row className="align-items-center justify-content-center">
                    <Image
                        className="loginLogo"
                        alt="Logo Editora Integrative"
                        src={Logo}
                        fluid
                    />
                </Row>
                <Row className="align-items-center justify-content-center">
                    <h2>Editora Integrative</h2>
                </Row>
            </Container>
            <Container className="container d-flex justify-content-center align-content-center m-auto">
                <Form style={{ width: "30em" }} className="text-left">
                    <Form.Group className="mb-3" controlId="emailLogin">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            ref={userRef}
                            type="email"
                            placeholder="Enter email"
                            required
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    email: e.target.value,
                                });
                            }}
                            value={user.email}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="passwordLogin">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            required
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    password: e.target.value,
                                });
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Email e/ou password invalido.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Mantenha-me Logado."
                        />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="forgotPassword">
                        <Link to="/forgotPassword">Esqueci a senha!</Link>
                    </Form.Group> */}
                    <Form.Text
                        className={
                            errMsg ? "text-danger text-center mb-2" : "d-none"
                        }
                        ref={errRef}
                        aria-live="assertive"
                    >
                        {errMsg}
                    </Form.Text>
                    {isLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    ) : (
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={loginUser}
                        >
                            Submit
                        </Button>
                    )}
                </Form>
            </Container>
        </>
    );
};

export default Login;
