import React, { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { account } from "../appwrite/appwriteConfig";
import { Container, Spinner } from "react-bootstrap";

const RequiredAuth = () => {
    const { auth, setAuth } = useAuth();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function userSession() {
            try {
                await account.getSession("current").then((session) => {
                    setAuth({
                        userId: session.userId,
                        email: session.providerUid,
                        sessionId: session.$id,
                    });
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
        userSession();
    }, [setAuth]);

    return loading ? (
        <Container
            className="justify-content-center w-100"
            style={{ height: "100vh" }}
        >
            <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
            </Spinner>
        </Container>
    ) : auth?.userId ? (
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default RequiredAuth;
