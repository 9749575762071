import "./App.css";
import { Route, Routes } from "react-router-dom";
import DashBoard from "./components/DashBoard";
import BookView from "./components/BookView";
import Login from "./components/Login";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import RequiredAuth from "./components/RequiredAuth";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* public routes */}
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />

                {/* private routes */}
                <Route element={<RequiredAuth />}>
                    <Route path="/" element={<Home />} />
                    <Route path="library" element={<DashBoard />} />
                    <Route path="bookview" element={<BookView />} />
                </Route>

                {/* catch all */}
                <Route path="*" element={<Missing />} />
            </Route>
        </Routes>
    );
}

export default App;
