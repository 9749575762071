import React, { useEffect, useState, useRef } from "react";
import { databases } from "../appwrite/appwriteConfig";
import {
    Container,
    Row,
    Button,
    Nav,
    Spinner,
    Modal,
    InputGroup,
    Form,
} from "react-bootstrap";
import { usePdf } from "@mikecousins/react-pdf";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import getBook from "../hooks/getBook";
import PDF from "../docs/error.pdf";

const BookView = () => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const [lastPageViewed, setLastPageViewed] = useState();
    const [page, setPage] = useState(1);
    const [zoom, setZoom] = useState(1.0);
    const [totalPages, setTotalPages] = useState();
    const [firstPageLoaded, setFirstPageLoaded] = useState(false);
    const [documentId, setDocumentId] = useState();
    const [bookUrl, setBookUrl] = useState(localStorage.getItem("bookUrl"));
    const [bookError, setBookError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [navigatePage, setNavigatePage] = useState();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState("");

    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        file: bookUrl,
        page,
        canvasRef,
        scale: zoom,
    });

    const handleClose = () => setShowModal(false);
    const handleShow = () => {
        setErrMsg("");
        setShowModal(true);
    };
    const handlePageNavigate = () => {
        setErrMsg("");
        if (isNaN(navigatePage)) {
            setErrMsg("Somente Numeros");
        } else if (navigatePage < 1 || navigatePage > totalPages) {
            setErrMsg("Número da página inválido");
        } else {
            setPage(parseFloat(navigatePage));
            handleClose();
        }
    };

    useEffect(() => {
        async function fetchBook() {
            try {
                const url = await getBook();
                setBookUrl(url);
            } catch (error) {
                setBookUrl(PDF);
                setBookError(true);
                console.error(error);
            }
        }
        fetchBook();
    }, []);

    const getLastPage = async () => {
        try {
            const listDocuments = await databases.listDocuments(
                process.env.REACT_APP_DB_ID,
                process.env.REACT_APP_Collection_ID
            );
            if (listDocuments && listDocuments.documents.length > 0) {
                listDocuments.documents.forEach((el) => {
                    if (el.user_id === auth.userId) {
                        setLastPageViewed(el.page);
                        setDocumentId(el.$id);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setLastPageOnDb = async () => {
        try {
            if (documentId) {
                await databases.updateDocument(
                    process.env.REACT_APP_DB_ID,
                    process.env.REACT_APP_Collection_ID,
                    documentId,
                    {
                        page: page.toString(),
                    }
                );
            } else {
                await databases.createDocument(
                    process.env.REACT_APP_DB_ID,
                    process.env.REACT_APP_Collection_ID,
                    "unique()",
                    {
                        book_id: `${auth.userId}_AlemDoSono`,
                        user_id: auth.userId,
                        page: page.toString(),
                    }
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (bookError) {
            navigate("/library");
        }
    }, [bookError, navigate]);

    const closeBook = (e) => {
        e.preventDefault();
        getLastPage();
        if (!bookError) {
            setLastPageOnDb();
        }

        navigate("/library");
    };

    useEffect(() => {
        setErrMsg("");
        setFirstPageLoaded(false);
        getLastPage();
        const handleContextmenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener("contextmenu", handleContextmenu);
        return function cleanup() {
            document.removeEventListener("contextmenu", handleContextmenu);
            setFirstPageLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFirstPageLoaded(false);

        const onDocumentLoadSuccess = (pdf) => {
            setTotalPages(pdf.numPages);
            setTimeout(() => {
                if (lastPageViewed) {
                    setPage(parseFloat(lastPageViewed));
                }
                setFirstPageLoaded(true);
            }, 500);
        };

        if (pdfDocument) {
            onDocumentLoadSuccess(pdfDocument);
        }
    }, [pdfDocument, lastPageViewed]);

    return (
        <>
            <Container fluid className="d-flex justify-content-center">
                <Button
                    className="position-absolute book-close-btn"
                    onClick={closeBook}
                    variant="outline-dark"
                    style={{ right: "27vw" }}
                >
                    X
                </Button>
                <div className="position-absolute">
                    <Button
                        className="zoomOut border-0"
                        disabled={zoom === 1.0}
                        onClick={() => setZoom(zoom - 0.1)}
                        variant="outline-dark"
                    >
                        - Zoom
                    </Button>
                    <Button
                        className="zoomIn border-0"
                        disabled={zoom === 5.0}
                        onClick={() => setZoom(zoom + 0.1)}
                        variant="outline-dark"
                    >
                        + Zoom
                    </Button>
                </div>
                <div className="change-page position-absolute">
                    <Button variant="outline-dark" onClick={handleShow}>
                        Página
                    </Button>
                    <Modal
                        size="sm"
                        centered
                        show={showModal}
                        onHide={handleClose}
                    >
                        <Modal.Body>
                            <InputGroup>
                                <Form.Control
                                    placeholder="Página"
                                    aria-label="Página"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        setNavigatePage(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="primary"
                                    id="button-addon2"
                                    onClick={handlePageNavigate}
                                >
                                    Navegar
                                </Button>
                            </InputGroup>
                            <Form.Text
                                className={
                                    errMsg
                                        ? "text-danger text-center mb-2"
                                        : "d-none"
                                }
                                ref={errRef}
                                aria-live="assertive"
                            >
                                {errMsg}
                            </Form.Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                {!pdfDocument ? (
                    <Container
                        className="d-flex justify-content-center"
                        style={{ height: "100vh" }}
                    >
                        <Spinner
                            className="my-auto"
                            as="span"
                            animation="border"
                            size="xl"
                            role="status"
                            aria-hidden="true"
                        />
                    </Container>
                ) : (
                    <>
                        <div
                            className={
                                "position-absolute bg-white loadingFirstPage m-3 " +
                                (firstPageLoaded ? "d-none" : " d-flex")
                            }
                        >
                            <h2>Carregando...</h2>
                        </div>
                        <canvas className="book-canvas" ref={canvasRef} />
                    </>
                )}
            </Container>
            {pdfDocument && (
                <>
                    <Row className="d-flex justify-content-center w-100 mb-1">
                        {Boolean(
                            firstPageLoaded &&
                                pdfDocument &&
                                pdfDocument.numPages
                        ) && (
                            <>
                                <Row className="">
                                    <span className="page-info">
                                        Page{" "}
                                        <span className="page-num">{page}</span>{" "}
                                        of{" "}
                                        <span className="page-count">
                                            {totalPages}
                                        </span>
                                    </span>
                                </Row>
                                <Nav className="fixed-bottom m-auto justify-content-center">
                                    <Button
                                        disabled={page === 1}
                                        onClick={() => setPage(page - 1)}
                                        variant="outline-dark"
                                    >
                                        <i className="bi bi-caret-left"></i>
                                        Prev Page
                                    </Button>
                                    <Button
                                        disabled={page === pdfDocument.numPages}
                                        onClick={() => setPage(page + 1)}
                                        variant="outline-dark"
                                    >
                                        Next Page
                                        <i className="bi bi-caret-right"></i>
                                    </Button>
                                </Nav>
                            </>
                        )}
                    </Row>
                </>
            )}
        </>
    );
};

export default BookView;
